import(/* webpackMode: "eager" */ "/vercel/path0/next-portfolio/app/global.css");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/next-portfolio/components/layout/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/next-portfolio/components/store-provider/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/next-portfolio/components/theme-provider/index.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@14.2.15_@babel+core@7.25.8_@playwright+test@1.48.0_babel-plugin-macros@3.1.0_react-dom@1_ld3ga3dhnjf5onjolbvnq7nldm/node_modules/next/font/local/target.css?{\"path\":\"../node_modules/.pnpm/geist@1.3.1_next@14.2.15_@babel+core@7.25.8_@playwright+test@1.48.0_babel-plugin-macros@3.1.0_g5y7jbdbr663kbe6mmgh6s7i64/node_modules/geist/dist/sans.js\",\"import\":\"\",\"arguments\":[{\"src\":\"./fonts/geist-sans/Geist-Variable.woff2\",\"variable\":\"--font-geist-sans\",\"weight\":\"100 900\"}],\"variableName\":\"GeistSans\"}");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@14.2.15_@babel+core@7.25.8_@playwright+test@1.48.0_babel-plugin-macros@3.1.0_react-dom@1_ld3ga3dhnjf5onjolbvnq7nldm/node_modules/next/font/local/target.css?{\"path\":\"../node_modules/.pnpm/geist@1.3.1_next@14.2.15_@babel+core@7.25.8_@playwright+test@1.48.0_babel-plugin-macros@3.1.0_g5y7jbdbr663kbe6mmgh6s7i64/node_modules/geist/dist/mono.js\",\"import\":\"\",\"arguments\":[{\"src\":\"./fonts/geist-mono/GeistMono-Variable.woff2\",\"variable\":\"--font-geist-mono\",\"adjustFontFallback\":false,\"fallback\":[\"ui-monospace\",\"SFMono-Regular\",\"Roboto Mono\",\"Menlo\",\"Monaco\",\"Liberation Mono\",\"DejaVu Sans Mono\",\"Courier New\",\"monospace\"],\"weight\":\"100 900\"}],\"variableName\":\"GeistMono\"}");
